.RecoveryForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.FormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FormInput {
  width: 21.75vw !important;
  height: 2.515vw !important;
  margin: 2px 0 5px 0;
  border: 1px solid #ccdbec;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
}

.ButtonWrapper {
  display: flex;
  justify-content: space-between;
  align-self: center;
  width: 21.75vw;
}

.Button {
  width: 7vw !important;
  height: 2.5vw !important;
}

.Title {
  text-align: center;
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1vw;
  line-height: 1.5vw;
}

.Description {
  margin-top: 2vw;
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8vw;
  line-height: 0.95vw;
  color: #838c9d;
}

@media only screen and (max-width: 1280px) {
  .Button {
    width: 8vw !important;
  }

  .Description {
    font-size: 1vw;
  }
}

@media only screen and (max-width: 1024px) {
  .Title {
    font-size: 2.5vw;
  }

  .FormInput {
    width: 33.75vw !important;
    height: 4.815vw !important;
  }

  .ButtonWrapper {
    width: 33.75vw;
    height: 4.815vw;
  }
  .Button {
    width: 11vw !important;
    height: 4vw !important;
  }

  .Description {
    padding-left: 2vw;
    font-size: 1.4vw;
    line-height: 1.7vw;
  }
}

@media only screen and (max-width: 768px) {
  .Title {
    font-size: 2.5vw;
  }

  .ButtonWrapper,
  .FormInput {
    width: 40vw !important;
    height: 5vw !important;
  }

  .Button {
    width: 14vw !important;
    height: 5vw !important;
  }

  .Description {
    padding-top: 2vw;
    font-size: 1.9vw;
    line-height: 2.1vw;
  }
}

@media only screen and (max-width: 430px) {
  .Title {
    font-size: 4.5vw;
    margin-bottom: 10vw;
  }

  .ButtonWrapper,
  .FormInput {
    width: 80vw !important;
    height: 10vw !important;
  }

  .Button {
    width: 27vw !important;
    height: 8vw !important;
  }

  .Description {
    width: 80%;
    text-align: center;
    font-size: 3.5vw;
    line-height: 4vw;
  }
}
