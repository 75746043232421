.Loading {
  display: flex;
  flex-direction: row;
  color: var(--blue);
  margin: 0 auto;
  align-items: center;
}

.Arrow {
  margin-right: 1.5vw;
}

.WrapperLoading {
  position: absolute;
  top: 50%;
  left: 50%;
}

.Arrow {
  animation: right 3s linear infinite;
}

@keyframes right {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1333px) {
  .WrapperLoading {
    position: absolute;
    top: 50%;
    left: 45%;
  }
}

@media only screen and (max-width: 1024px) {
  .WrapperLoading {
    position: absolute;
    top: 50%;
    left: 45%;
  }

  .Arrow {
    margin-right: 1.9vw;
  }
}

@media only screen and (max-width: 768px) {
  .WrapperLoading {
    position: absolute;
    top: 50%;
    left: 40%;
  }

  .Arrow {
    margin-right: 2.3vw;
  }
}

@media only screen and (max-width: 430px) {
  .WrapperLoading {
    position: absolute;
    top: 50%;
    left: 35%;
  }

  .Arrow {
    margin-right: 4vw;
  }
}
