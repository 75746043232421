.FormInput {
  width: 18.75vw;
  height: 2.7vw;
  margin: 0.1vw 0 0.4vw 0;
  border: 1px solid #ccdbec;
  box-sizing: border-box;
  border-radius: 8px;
  color: var(--gray);
  outline: none;

  font-family: var(--font-family-roboto);
  font-style: normal;
  font-weight: normal;
  font-size: 1vw;
  line-height: 1.1vw;
}

.FormInput[type='text'],
.FormInput[type='email'],
.FormInput[type='password'] {
  padding-left: 0.781vw;
}

.FormInput:required:valid {
  border-color: green;
}

.InputWrapper {
  display: flex;
  flex-direction: column;
}

.hiddenEye .limb {
  stroke: #cd803d;
  stroke-width: 35px;
  stroke-linecap: round;
}

.cross,
.eyeIcon {
  position: relative;
  cursor: pointer;
  border: none;
  background: none;
}

.eyeIcon {
  position: absolute;
  height: 0.93vw;
  width: 1.25vw;
  left: 0;
  right: 0;
  top: 0.05vw;
  bottom: 0.05vw;
}

.cross {
  right: 2vw;
}

.crossIcon {
  display: inline-block;
  fill: grey;
  line-height: 1;
  stroke: transparent;
  stroke-width: 0;
}

.InputLabel {
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8vw;
  line-height: 1.1vw;
  color: #838c9d;
}

@media only screen and (max-width: 1024px) {
  .Column:last-child {
    margin-left: 7vw;
  }

  .ColumnTitle {
    font-size: 2.3vw;
    line-height: 2.5vw;
  }

  .eyeIcon {
    position: absolute;
    height: 1.83vw;
    width: 2.15vw;
    left: 0;
    right: 0;
    top: 0.1vw;
    bottom: 0.2vw;
  }

  .cross {
    right: 4vw;
  }

  .FormInput {
    width: 32vw;
    height: 4vw;
    margin: 0.5vw 0 1.7vw 0;
    font-size: 2vw;
    line-height: 2.2vw;
  }

  .FormInput[type='text'],
  .FormInput[type='tel'],
  .FormInput[type='password'] {
    padding-left: 1.5vw;
  }

  .InputLabel {
    font-size: 1.7vw;
    line-height: 2vw;
  }

  .FormInput::-webkit-input-placeholder {
    font-size: 2vw;
    line-height: 2.2vw;
  }
}

@media only screen and (max-width: 768px) {
  .Column:last-child {
    margin-left: 0;
  }

  .ColumnTitle {
    font-size: 3vw;
    line-height: 3.1vw;
  }

  .eyeIcon {
    position: absolute;
    height: 2.3vw;
    width: 3.15vw;
    left: 0;
    right: 0;
    top: 0.1vw;
    bottom: 0.2vw;
  }

  .cross {
    right: 5vw;
  }

  .FormInput {
    width: 50vw;
    height: 5.5vw;
    margin: 0.5vw 0 1.7vw 0;

    font-size: 2.5vw;
    line-height: 2.7vw;
  }

  .FormInput[type='text'],
  .FormInput[type='tel'],
  .FormInput[type='password'] {
    padding-left: 1.8vw;
  }

  .InputLabel {
    font-size: 2.3vw;
    line-height: 2.5vw;
    color: var(--grey-opacity);
  }

  .FormInput::-webkit-input-placeholder {
    font-size: 2.3vw;
    line-height: 2.5vw;
  }
}

@media only screen and (max-width: 430px) {
  .ColumnTitle {
    font-size: 4vw;
    line-height: 4.1vw;
  }

  .FormInput {
    width: 100%;
    height: 10vw;
    margin: 1vw 0 2vw 0;
    font-size: 4.5vw;
    line-height: 4.7vw;
  }

  .eyeIcon {
    position: absolute;
    height: 4.3vw;
    width: 5.15vw;
    left: -2vw;
    right: 0;
    bottom: 0.3vw;
  }

  .cross {
    right: 6vw;
  }

  .FormInput[type='text'],
  .FormInput[type='tel'],
  .FormInput[type='password'] {
    padding-left: 2vw;
  }

  .InputLabel {
    font-size: 4vw;
    line-height: 4.2vw;
    color: var(--grey-opacity);
  }

  .FormInput::-webkit-input-placeholder {
    font-size: 4vw;
    line-height: 4.2vw;
  }
}
