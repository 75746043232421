.ColumnTitle {
  font-family: var(--font-family-notosans);
  font-style: normal;
  font-weight: 500;
  font-size: 1.1vw;
  line-height: 1.5vw;
  color: var(--gray);
}

.WrapperPasswordContainer {
  margin-left: 5.5vw;
}

.FormItem {
  margin: 0;
}

.WrapperFormsContainer {
  display: flex;
}

.FormFieldset {
  margin: 0;
  padding: 0;
  border: none;
}

.InputWrapper {
  display: flex;
  flex-direction: column;
}

.FormInput {
  width: 18.75vw;
  height: 2.7vw;
  margin: 0.1vw 0 0.4vw 0;
  border: 1px solid #ccdbec;
  box-sizing: border-box;
  border-radius: 8px;
  color: var(--gray);
  outline: none;

  font-family: var(--font-family-roboto);
  font-style: normal;
  font-weight: normal;
  font-size: 1vw;
  line-height: 1.1vw;
}

.PhoneInput {
  width: 18.75vw !important;
  height: 2.7vw !important;
  border: 1px solid #ccdbec !important;
  border-radius: 8px !important;
  color: var(--gray) !important;

  font-family: var(--font-family-roboto) !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 1vw !important;
  line-height: 1.1vw !important;
}

.PhoneContainer {
  margin: 2px 0 5px 0;
  border: none;
  box-sizing: border-box;
  outline: none;
}

.FormInput:required:valid {
  border-color: green;
}

.Button {
  width: 18.75vw;
  height: 2.7vw;
  margin: 1vw 0 1vw 0;
}

.InputLabel {
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8vw;
  line-height: 1.1vw;
  color: #838c9d;
}

.FormInput::-webkit-input-placeholder {
  font-family: var(--font-family-roboto);
  font-style: normal;
  font-weight: normal;
  font-size: 1vw;
  line-height: 1.1vw;
  opacity: 0.5;
}

.FormInput:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.6s ease;
}

@media only screen and (max-width: 1024px) {
  .Column:last-child {
    margin-left: 7vw;
  }

  .ColumnTitle {
    font-size: 2.3vw;
    line-height: 2.5vw;
  }

  .FormInput {
    width: 32vw;
    height: 4vw;
    margin: 0.5vw 0 1.7vw 0;
    font-size: 2vw;
    line-height: 2.2vw;
  }

  .PhoneInput {
    width: 32vw !important;
    height: 4vw !important;
    margin: 0.5vw 0 1.7vw 0 !important;
    font-size: 2vw !important;
    line-height: 2.2vw !important;
  }

  .FormInput[type='text'],
  .FormInput[type='email'],
  .FormInput[type='password'] {
    padding-left: 1.5vw;
  }

  .Button {
    width: 32vw;
    height: 4vw;
    margin: 2vw 0 0 0;
  }

  .InputLabel {
    font-size: 1.7vw;
    line-height: 2vw;
  }

  .FormInput::-webkit-input-placeholder {
    font-size: 2vw;
    line-height: 2.2vw;
  }
}

@media only screen and (max-width: 768px) {
  .FormFieldset {
    flex-direction: column;
  }

  .WrapperFormsContainer {
    flex-direction: column;
  }

  .WrapperPasswordContainer {
    margin-left: 0;
  }

  .Column:last-child {
    margin-left: 0;
  }

  .ColumnTitle {
    font-size: 3vw;
    line-height: 3.1vw;
  }

  .FormInput {
    width: 50vw;
    height: 5.5vw;
    margin: 0.5vw 0 1.7vw 0;
    font-size: 2.5vw;
    line-height: 2.7vw;
  }

  .PhoneInput {
    width: 50vw !important;
    height: 5.5vw !important;
    margin: 0.5vw 0 1.7vw 0 !important;
    font-size: 2.5vw !important;
    line-height: 2.7vw !important;
  }

  .FormInput[type='text'],
  .FormInput[type='email'],
  .FormInput[type='password'] {
    padding-left: 1.8vw;
  }

  .Button {
    width: 50vw;
    height: 5.5vw;
  }

  .InputLabel {
    font-size: 2.3vw;
    line-height: 2.5vw;
    color: var(--grey-opacity);
  }

  .FormInput::-webkit-input-placeholder {
    font-size: 2.3vw;
    line-height: 2.5vw;
  }
}

@media only screen and (max-width: 430px) {
  .ColumnTitle {
    font-size: 4vw;
    line-height: 4.1vw;
  }

  .WrapperFormsContainer {
    flex-direction: column;
  }

  .WrapperPasswordContainer {
    margin-left: 0;
  }

  .FormInput {
    width: 100%;
    height: 10vw;
    margin: 1vw 0 2vw 0;
    font-size: 4.5vw;
    line-height: 4.7vw;
  }

  .PhoneInput {
    width: 100% !important;
    height: 10vw !important;
    margin: 1vw 0 2vw 0 !important;
    font-size: 4.5vw !important;
    line-height: 4.7vw !important;
  }

  .FormInput[type='text'],
  .FormInput[type='email'],
  .FormInput[type='password'] {
    padding-left: 2vw;
  }

  .Button {
    margin: 5vw 0;
    height: 10vw;
    width: 100%;
  }

  .InputLabel {
    font-size: 4vw;
    line-height: 4.2vw;
    color: var(--grey-opacity);
  }

  .FormInput::-webkit-input-placeholder {
    font-size: 4vw;
    line-height: 4.2vw;
  }
}
