.Section {
  display: flex;
  align-items: center;
  height: 3vw;
}

.Select {
  margin-left: 1vw;
  width: 20vw;
}

.CityTitle {
  margin: 0 2.3vw 0 0;
}

@media (-webkit-min-device-pixel-ratio: 1.25) {
  .CityTitle {
    margin: 0 3vw 0 0;
  }
}

@media only screen and (max-width: 1440px) {
  .CityTitle {
    margin: 0 3vw 0 0;
  }
}

@media only screen and (max-width: 1280px) {
  .CityTitle {
    margin: 0 3.5vw 0 0;
  }
}

@media only screen and (max-width: 1024px) {
  .Select {
    margin-left: 2vw;
    width: 35vw;
  }

  .Section {
    height: 4vw;
  }

  .CityTitle {
    margin: 0 4.4vw 0 0;
  }
}

@media only screen and (max-width: 768px) {
  .Select {
    width: 40vw;
  }

  .Section {
    height: 6vw;
  }

  .CityTitle {
    margin: 0 6vw 0 0;
  }
}

@media only screen and (max-width: 430px) {
  .Select {
    width: 60vw;
  }

  .Section {
    height: 10vw;
  }

  .CityTitle {
    margin: 0 10.5vw 0 0;
  }
}

@media only screen and (max-width: 375px) {
  .Section {
    height: 12vw;
  }

  .CityTitle {
    margin: 0 12vw 0 0;
  }
}
