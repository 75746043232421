.Title {
  margin: 0;
  font-family: var(--font-family-notosans);
  font-style: normal;
  font-size: 2.2vw !important;
  line-height: 3.5vw;
  color: #1677ff !important;
  font-weight: 500 !important;
}

.ProfileInformation {
  font-family: var(--font-family-notosans);
  font-size: var(--font-size-xxs);
  color: var(--grey-opacity);
}

.UserName {
  margin-bottom: 1vw;
  font-family: var(--font-family-notosans);
  font-style: normal;
  font-weight: 600;
  font-size: 1.5vw;
  line-height: 2.1vw;
  color: var(--gray);
}

@media only screen and (max-width: 1024px) {
  .Title {
    font-size: 4vw !important;
    line-height: 4.2vw !important;
  }

  .UserName {
    margin: 2vw 0 2vw 0;
    font-size: 3vw;
    line-height: 3.2vw;
  }
}

@media only screen and (max-width: 768px) {
  .Title {
    font-size: 5vw !important;
    line-height: 7.2vw !important;
  }

  .UserName {
    margin: 5vw 0 2vw 0;
    font-size: 5vw;
    line-height: 5.2vw;
  }
}

@media only screen and (max-width: 430px) {
  .Title {
    font-size: 7vw !important;
    margin-top: 4vw;
  }

  .ProfileInformation {
    font-family: var(--font-family-notosans);
    font-size: var(--font-size-xs);
    color: var(--grey-opacity);
  }
}
