.ColumnTitle {
  font-family: var(--font-family-notosans);
  font-style: normal;
  font-weight: 500;
  font-size: 1.1vw;
  line-height: 1.5vw;
  color: var(--gray);
}

.FormItem {
  margin: 0.1vw 0 0.4vw 0;
}

.FormFieldset {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  border: none;
}

.FormInput {
  width: 18.75vw;
  height: 2.7vw;
  border: 1px solid #ccdbec;
  box-sizing: border-box;
  border-radius: 8px;
  color: var(--gray);
  outline: none;

  font-family: var(--font-family-roboto);
  font-style: normal;
  font-weight: normal;
  font-size: 1vw;
  line-height: 1.1vw;
}

.Button {
  width: 18.75vw;
  height: 2.7vw;
  margin: 1vw 0 1vw 0;
}

.InputLabel {
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8vw !important;
  line-height: 1.1vw;
  color: #838c9d;
}

.FormInput::-webkit-input-placeholder {
  font-family: var(--font-family-roboto);
  font-style: normal;
  font-weight: normal;
  font-size: 1vw;
  line-height: 1.1vw;
  opacity: 0.5;
}

.FormInput:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.6s ease;
}

@media only screen and (max-width: 1024px) {
  .ColumnTitle {
    font-size: 2.3vw;
    line-height: 2.5vw;
  }

  .FormInput {
    width: 32vw;
    height: 4vw;
    margin: 0.5vw 0 1.7vw 0;
    font-size: 2vw;
    line-height: 2.2vw;
  }

  .Button {
    width: 32vw;
    height: 4vw;
    margin: 1vw 0 0 0;
  }

  .InputLabel {
    font-size: 1.7vw !important;
    line-height: 2vw !important;
  }

  .FormInput::-webkit-input-placeholder {
    font-size: 2vw;
    line-height: 2.2vw;
  }
}

@media only screen and (max-width: 768px) {
  .FormFieldset {
    flex-direction: column;
  }

  .Column:last-child {
    margin-left: 0;
  }

  .FormContainer {
    margin-left: 0;
  }

  .ColumnTitle {
    font-size: 3vw;
    line-height: 3.1vw;
  }

  .FormInput {
    width: 50vw;
    height: 5.5vw;
    margin: 0.5vw 0 1.7vw 0;
    font-size: 2.5vw;
    line-height: 2.7vw;
  }

  .Button {
    width: 50vw;
    height: 5.5vw;
  }

  .InputLabel {
    font-size: 2.3vw !important;
    line-height: 2.5vw !important;
    color: var(--grey-opacity);
  }

  .FormInput::-webkit-input-placeholder {
    font-size: 2.3vw;
    line-height: 2.5vw;
  }
}

@media only screen and (max-width: 430px) {
  .ColumnTitle {
    font-size: 4vw;
    line-height: 4.1vw;
  }

  .FormContainer {
    margin-left: 0;
  }

  .FormInput {
    width: 100%;
    height: 10vw;
    margin: 1vw 0 2vw 0;
    font-size: 4.5vw;
    line-height: 4.7vw;
  }

  .Button {
    margin: 5vw 0;
    width: 100%;
    height: 10vw !important;
  }

  .InputLabel {
    font-size: 4vw !important;
    line-height: 4.2vw !important;
    color: var(--grey-opacity);
  }
}
