.Logo {
  margin: 10% 10% 10% 22%;
  width: 50%;
  height: auto;
}

.Menu {
  border: none !important;
}

.Sider {
  width: 260px !important;
  background-color: #ffffff !important;
  border-right: 2px solid rgba(224, 224, 224, 0.52);
}
