.Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 7vw;
}

.Logo {
    width: 20vw;
    height: auto;
    margin-bottom: 3vw;
}

@media only screen and (max-width: 1024px) {
    .Logo {
        width: 30vw;
    }
}

@media only screen and (max-width: 768px) {
    .Wrapper {
        padding-top: 10vw;
    }
}

@media only screen and (max-width: 430px) {
    .Logo {
        width: 50vw;
    }
}
