.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.Section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  height: 15vw;
}

.Link {
  padding-top: 3vw;
  text-decoration: none;
}

.Status {
  font-family: var(--font-family-roboto);
  font-size: 4vw;
  font-weight: bold;
  color: red;
  margin-bottom: 3vw;
}

.Support {
  padding-top: 1vw;
  display: flex;
}

.Contacts {
  display: flex;
  flex-direction: column;
  margin-left: 1vw;
}

.Info {
  margin: 0 0 0.5vw 0;
  padding: 0;
}

.Button {
  padding: 1vw 1.7vw;
  font-size: 1.2vw;
}

@media only screen and (max-width: 1024px) {
  .Status {
    font-size: 5.5vw;
    margin-bottom: 3vw;
  }

  .Support {
    padding-top: 2vw;
  }

  .Button {
    padding: 1.5vw 2vw;
    font-size: 2.5vw;
  }
}
@media only screen and (max-width: 768px) {
  .Status {
    font-size: 7vw;
  }

  .Contacts {
    margin-left: 3vw;
  }

  .Support {
    padding-top: 3vw;
  }

  .Button {
    padding: 2vw 2.5vw;
    font-size: 2.7vw;
  }

  .Link {
    margin-top: 7vw;
  }
}

@media only screen and (max-width: 430px) {
  .Container {
    font-size: 3.4vw;
  }

  .Status {
    font-size: 7vw;
    margin-bottom: 5vw;
  }

  .Contacts {
    margin-left: 3vw;
  }

  .Button {
    padding: 4vw 4.5vw;
    font-size: 4.5vw;
  }
}
