.Container {
  display: flex;
  justify-content: center;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.Button {
  margin-top: 1vw;
}

.Logo {
    align-self: center;
}

.Text {
    text-align: center;
}
