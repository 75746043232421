* {
  font-family: 'Exo 2', sans-serif !important;
}

.Radio_button_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 2.8vw;
  height: 2.8vw;
  aspect-ratio: 1;
  margin: 0.3vw;
  border-radius: 50%;
  background-color: #e2f4ff;
  transition: all 0.3s ease;
  font-weight: 700;
  cursor: pointer;
  color: #2aacf4;
}

.Radio_button_wrapper::after {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  right: 1px;
  bottom: 2px;
  background-color: #d9d9d9;
  user-select: none;
  touch-action: manipulation;
}

.Radio_button_wrapper.correct::after {
  background-color: #6fef83;
}

.Radio_button_wrapper.incorrect::after {
  background-color: #ff7278;
}

.Radio_button_wrapper.unanswered {
  background-color: #f0f0f0;
  color: #9e9e9e;
  cursor: default;
}

.Radio_button_wrapper.unanswered:hover {
  background-color: #f0f0f0;
  color: #9e9e9e;
}

.Radio_button_wrapper.unanswered span {
  font-size: 14px;
  color: #9e9e9e;
}

.Radio_button_wrapper:hover,
.Radio_button_wrapper.active {
  background-color: #1890ff;
  border-color: #1890ff;
  color: #fff;
}

.NavigationButton {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  outline: none;
  width: 30px;
  height: 30px;
  position: relative;
}

.NavigationButton::before,
.NavigationButton::after {
  content: '';
  position: absolute;
  top: 50%;
  border: solid #333;
  border-width: 0 3px 3px 0;
  display: block;
  padding: 3px;
  transform-origin: 50% 50%;
}

.PrevButton::before {
  transform: translateX(-50%) translateY(-50%) rotate(135deg);
}

.PrevButton::after {
  content: none;
}

.NextButton::before {
  transform: translateX(50%) translateY(-50%) rotate(-45deg);
}

.NextButton::after {
  content: none;
}

.Radio_button_inner {
  display: none;
}

.QuestionWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.QuestionCircles {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.QuestionNumber {
  font-size: 18px;
  margin-right: 10px;
}

.QuestionPicture {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}

.TestingContainer {
  display: flex;
  align-items: center;
}

.QuestionBlock,
.AnswerBlock {
  flex: 1;
}

.QuestionCirclesContainer {
  width: fit-content;
  position: absolute;
  top: 10px;
  left: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.QuestionAnswerBlock {
  margin: 0 10px;
  box-sizing: border-box;
}

.QuestionAnswerBlock.QuestionBlock,
.QuestionAnswerBlock.AnswerBlock {
  align-self: flex-start;
  width: 45vw;
}

.QuestionAnswerBlock.QuestionBlock {
  height: 35vw;
  border-radius: 8px;
  margin-top: 20px;
  margin-left: 30px;
  padding: 20px;
  font-size: 20px;
  line-height: 30px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.QuestionAnswerBlock.AnswerBlock {
  padding-top: 20px;
  margin-right: 30px;
  font-size: 16px;
  width: 50vw;
  max-height: 35vw;
  overflow: auto;
}

.AnswerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 12px;
  color: #000;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 3px solid white;
}

.AnswerContainer.correct {
  border: 3px solid #6fef83;
  color: #00c52b;
}

.AnswerContainer.noHover:hover {
  background-color: #ffffff;
  color: #000000;
}

.AnswerContainer.incorrect {
  border: 3px solid #ff7278;
  color: #ff7278;
}

.RadioInput {
  display: none;
}

.AnswerContainer {
  cursor: pointer;
  transition: 0.3s;
}

.AnswerContainer:hover {
  border: 3px solid #2aacf4;
}

.ActiveAnswer {
  background-color: #2aacf4;
  border: 3px solid #2aacf4;
  color: #fff;
}

.AnswerIcon {
  position: absolute;
  left: -20px;
  width: 24px;
  height: 24px;
  background-color: #1890ff;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
}

.AnswerContainer.unselected .AnswerIcon {
  background-color: #f0f0f0;
  color: #1890ff;
}

.RadioWrapper {
  display: flex;
  flex-direction: column;
}

.QuestionCirclesContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 8px;
  background-color: #ffffff;
  border-radius: 8px;
  margin-left: 30px;
}

.TestingFormContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.AnswerNumber {
  width: 37px;
  height: 37px;
  line-height: 24px;
  border-radius: 50%;
  background-color: #e1f3ff;
  color: #2aacf4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
  flex-shrink: 0;
}

.QuestionNumber {
  font-family: var(--font-family-notosans);
  font-style: normal;
  font-weight: 100;
  font-size: 2.5vw;
  line-height: 3.5vw;
  color: #4b9bfa;
}

.FormContainer {
  display: flex;
  flex-direction: column;
}

.TestingFormField {
  display: flex;
  flex-direction: column;
  border: none;
}

.RadioLabel {
  cursor: pointer;
  font-size: 1vw;
}

.RedColor {
  color: red;
}

.GreenColor {
  color: #00c52b;
}

.ButtonWrapper {
  position: absolute;
  bottom: 0;
  width: 95.5vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 8px;
  margin: 25px 25px 10px 25px;
  height: 100px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ButtonContainer {
  display: flex;
  margin-right: 2vw;
}

.ButtonContainer button {
  margin-left: 1.7vw;
}

.CountQuestions {
  display: flex;
  align-items: center;
  margin-left: 30px;
  padding: 20px;
  color: #55b9f6;
  font-weight: 500;
  font-size: 1.8vw;
  line-height: 2vw;
  height: 3.5vw;
  border-radius: 8px;
  background-color: #e1f3ff;
}

.Button {
  min-width: 17vw;
  height: 3.5vw;
  border: none;
  font-size: 20px;
  background-color: #2aacf4;
  color: var(--white);
  border-radius: 8px;
  font-weight: 600;
}

.Button:hover {
  background-color: #e1f3ff;
}

.FinishButton {
  width: 13vw;
  height: 2.6vw;
  margin-left: 19vw !important;
}

.PrevQuestionButton {
  background: none;
  border: 1px solid var(--gray);
  color: var(--gray);
}

.PrevQuestionButton:hover {
  border: none;
}

.Link:hover {
  box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
}

.RightAnswer {
  position: absolute;
  left: 11vw;
  font-family: var(--font-family-notosans);
  font-style: normal;
  font-size: 1.6vw;
  line-height: 1.9vw;
  color: #00c52b;
}

.RightAnswer:hover {
  background-color: #ffffff !important;
}

.IncorrectAnswer {
  position: absolute;
  left: 11vw;
  font-family: var(--font-family-notosans);
  font-style: normal;
  font-size: 1.6vw;
  line-height: 1.9vw;
  color: red;
}

.textFromNPA {
  margin-top: 1.5vw;
  font-style: italic;
}

.Image,
.QuestionPicture {
  padding-top: 1vw;
  max-width: 10vw;
  height: auto;
}

.AnswerWrapper {
  display: flex;
  flex-direction: column;
}

@media (-webkit-min-device-pixel-ratio: 1.25) {
  .QuestionAnswerBlock.QuestionBlock {
    height: 28vw;
  }
}

@media only screen and (max-width: 1024px) {
  .QuestionCirclesContainer {
    display: none;
  }

  .TestingContainer {
    flex-direction: column;
    max-height: 550px;
    overflow-y: auto;
  }

  .QuestionAnswerBlock.QuestionBlock {
    height: auto;
    width: 90vw;
    max-height: 19vh;
    padding: 2vw;
    margin: 0;
    font-size: 1.6vw;
    line-height: 2vw;
  }

  .QuestionAnswerBlock.AnswerBlock {
    width: 90vw;
    padding: 0;
    margin: 2vw 0 0 0;
    font-size: 1.4vw;
    line-height: 1.6vw;
    max-height: 50vw;
  }

  .QuestionAnswerBlock.QuestionBlock h3 {
    margin: 0 0 1vw 0;
  }

  .RightAnswer {
    left: 19vw;
    font-size: 2.7vw;
  }

  .IncorrectAnswer {
    left: 19vw;
    font-size: 2.7vw;
  }

  .Button {
    width: 18vw;
    height: 4vw;
    font-size: 1.5vw;
  }

  .CountQuestions {
    padding-left: 5vw;
    font-size: 2.5vw;
  }

  .ButtonContainer {
    margin-left: 35vw;
  }

  .FinishButton {
    width: 22vw;
    height: 4vw;
    margin-left: 19vw !important;
  }
}

@media only screen and (max-width: 768px) {
  .TestingContainer {
    max-height: 750px;
  }

  .QuestionNumber {
    font-size: 4.3vw;
  }

  .QuestionAnswerBlock.QuestionBlock {
    font-size: 2.8vw;
    line-height: 3vw;
  }

  .QuestionAnswerBlock.AnswerBlock {
    font-size: 2.3vw;
    line-height: 2.6vw;
    max-height: 78vw;
  }

  .ButtonWrapper {
    flex-direction: column;
    gap: 40px;

    position: relative;
    width: 90%;
    padding: 15px;
    height: fit-content;

    margin: auto auto 0;
  }

  .Button {
    width: 28vw;
    height: 6vw;
    font-size: 2.3vw;
  }

  .FinishButton {
    width: 40vw;
    height: 6vw;
    font-size: 2.5vw;
    margin-left: 3vw !important;
  }

  .RightAnswer {
    position: relative;
    left: 0;
    font-size: 3.5vw;
  }

  .IncorrectAnswer {
    position: relative;
    left: 0;
    font-size: 3.5vw;
  }

  .ButtonContainer {
    margin: 0 auto;
    display: flex;
    gap: 8px;
  }

  .CountQuestions {
    margin: 0 auto;
    font-size: 3.5vw;
  }

  .TestingFormContainer {
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
  }

  .ButtonContainer button {
    margin: 0;
  }
}

@media only screen and (max-width: 430px) {
  .TestingContainer {
    max-height: 630px;
  }

  .QuestionAnswerBlock.QuestionBlock {
    height: fit-content;
    flex-shrink: 0;
    max-height: 19vh;
    font-size: 4vw;
    line-height: 4.5vw;
  }

  .QuestionAnswerBlock.AnswerBlock {
    font-size: 3.7vw;
    line-height: 4vw;
    max-height: 145vw;
  }

  .ButtonWrapper {
    width: 90%;
    padding-top: 15px;
  }

  .Image,
  .QuestionPicture {
    max-width: 15vw;
    height: auto;
  }

  .Button {
    width: 40vw;
    height: 12vw;
    font-size: 3.3vw;
  }

  .FinishButton {
    width: 45vw;
    height: 12vw;
    font-size: 3.6vw;
    margin-left: 5vw !important;
  }

  .RightAnswer {
    font-size: 5.3vw;
  }

  .IncorrectAnswer {
    font-size: 5.3vw;
  }

  .CountQuestions {
    font-size: 6vw;
  }

  .TestingFormContainer {
    padding-top: 2vw;
  }

  .QuestionWrapper {
    align-items: start;
  }
}

@media only screen and (max-width: 414px) {
  .QuestionAnswerBlock.AnswerBlock {
    font-size: 3.7vw;
    line-height: 4vw;
    max-height: 120vw;
  }
}

@media only screen and (max-width: 375px) {
  .TestingContainer {
    max-height: 380px;
  }

  .QuestionAnswerBlock.QuestionBlock {
    font-size: 3.5vw;
    line-height: 4vw;
  }

  .QuestionAnswerBlock.AnswerBlock {
    font-size: 3vw;
    line-height: 3.5vw;
  }
}

@media only screen and (max-width: 390px) {
  .TestingContainer {
    max-height: 550px;
  }
}

@media only screen and (max-height: 900px) {
  .TestingContainer {
    max-height: 600px;
  }
}

@media only screen and (max-height: 800px) {
  .TestingContainer {
    max-height: 470px;
  }
}

@media only screen and (max-height: 700px) {
  .TestingContainer {
    max-height: 400px;
  }

  .ButtonWrapper {
    gap: 20px;
  }
}
