:global .Toastify__toast {
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}

:global .Toastify__toast-body {
  margin: 0;
}

:global .Toastify__close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  opacity: 1;
  color: grey;
}

:global .Toastify__close-button:hover {
  opacity: 0.7;
}

:global .Toastify__toast-container {
  width: 100%;
  max-width: 400px;
}

.container {
  display: flex;
  min-height: 100%;
  background-color: #fff;
  box-shadow: 0 2px 10px grey;
  border-radius: 4px;
  font-style: normal;
  color: var(--gray);
  line-height: 20px;
}

.container::before {
  display: flex;
  justify-content: center;
  padding: 26px 0;
  width: 56px;
}

.contentWrapper {
  padding: 24px 16px;
  flex: 1;
}

.title {
  margin-bottom: 10px;
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-bold);
  text-transform: capitalize;
}

.content {
  font-size: var(--font-size-s);
}

.success {
  border: 1px solid #339a60;
}

.success::before {
  content: url(icons/success.svg);
  background-color: #339a60;
}

.error {
  border: 1px solid rgba(173, 0, 0, 0.8);
}

.error::before {
  content: url(icons/error.svg);
  background-color: rgba(173, 0, 0, 0.8);
}
