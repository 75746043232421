.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3vw;
}

.Logo {
  width: 20vw;
  height: auto;
  margin-bottom: 2vw;
}

@media only screen and (max-width: 1024px) {
  .Logo {
    width: 30vw;
  }
}

@media only screen and (max-width: 768px) {
  .Wrapper {
    padding-top: 7vw;
  }
}

@media only screen and (max-width: 430px) {
  .Logo {
    width: 50vw;
  }
}
