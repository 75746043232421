.Container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
  padding: 1vw;
  margin-top: 1.852vh;
  background: var(--white);
  border: 1px solid #ccdbec;
  border-radius: 18px;
  margin-right: 2vw;
}

.Wrapper {
  display: flex;
  justify-content: space-between;
}

.Count {
  margin: 0;
  padding: 0;
  font-family: var(--font-family-notosans);
  font-style: normal;
  font-weight: 500;
  font-size: 0.8vw;
  line-height: 1.1vw;
  color: var(--blue);
}

.DemoFlag {
  padding: 3px 8px;
  color: var(--gray);
  background-color: #cccccc;
  border-radius: 6px;

  font-family: var(--font-family-notosans);
  font-style: normal;
  font-weight: 500;
  font-size: 0.7vw;
  line-height: 1vw;
}

.TestName {
  font-family: var(--font-family-notosans);
  font-style: normal;
  font-weight: 600;
  font-size: 0.9vw;
  line-height: 1.3vw;
  color: var(--gray);
}

.ButtonWrapper {
  display: flex;
  flex-direction: column;
  align-self: end;
}

.Link {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  font-family: var(--font-family-roboto);
  font-size: 0.9vw;
  line-height: 1.3vw;
  width: inherit;
  height: inherit;
  text-decoration: none;
  padding: 0.556vh 0.885vw;
  margin-bottom: 1vw;
  color: black;
  border: 3px solid var(--blue);
}

.SecondaryLink {
  margin-bottom: 1vw;
  color: var(--blue);
  background: var(--white);
  border: 1px solid var(--blue);
}

.SecondaryLink:hover {
  box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
}

.Link:hover {
  box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
}

.ButtonForDoneTest {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Description {
  font-family: var(--font-family-notosans);
  font-style: normal;
  font-weight: normal;
  font-size: 0.8vw;
  line-height: 1.2vw;
  color: var(--grey-opacity);
}

@media only screen and (max-width: 1024px) {
  .Container {
    width: 40vw;
    padding: 1.5vw 1.5vw;
  }

  .Title {
    font-size: 6vw;
  }

  .Specialization {
    font-size: 2.2vw;
  }

  .Count,
  .Description,
  .TextInfo,
  .DemoFlag {
    font-size: 1.3vw;
    line-height: 1.4vw;
  }

  .TextInfo {
    margin: 0;
  }

  .TestName {
    font-size: 1.6vw;
    line-height: 1.7vw;
  }

  .Link {
    font-size: 1.8vw;
    padding: 1vw 2vw;
  }
}

@media only screen and (max-width: 768px) {
  .Container {
    width: 100%;
    padding: 2vw;
  }

  .Title {
    font-size: 8vw;
  }

  .Specialization {
    margin: 4vw 0 2vw 0;
    font-size: 2.8vw;
    line-height: 3vw;
  }

  .Count,
  .Description,
  .DemoFlag {
    font-size: 2.2vw;
    line-height: 2.4vw;
  }

  .TextInfo {
    font-size: 1.6vw;
    line-height: 2vw;
  }

  .TestName {
    font-size: 2.6vw;
    line-height: 2.8vw;
  }

  .Link {
    font-size: 2.7vw;
    padding: 1.5vw 2.5vw;
  }
}

@media screen and (max-width: 430px) {
  .Container {
    padding: 4vw;
  }

  .Title {
    font-size: 12vw;
  }

  .Specialization {
    margin: 6vw 0 4vw 0;
    font-size: 6vw;
    line-height: 9vw;
  }

  .Count,
  .Description {
    font-size: 4.2vw;
    line-height: 4.4vw;
  }

  .DemoFlag {
    font-size: 3vw;
    line-height: 3.4vw;
  }

  .Information {
    display: none;
  }

  .TestName {
    font-size: 4.6vw;
    line-height: 4.8vw;
  }

  .Link {
    font-size: 3vw;
    padding: 3vw 3vw;
  }
}
