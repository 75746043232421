.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 8px;
  background: none;
  cursor: pointer;
  outline: none;
  font-family: var(--font-family-roboto);
  font-size: 0.9vw;
  line-height: 1.3vw;
  width: inherit;
  height: inherit;
  text-decoration: none;
}

.Button:focus {
  outline: none;
}

.Blue {
  padding: 0.4vw 0.885vw;
  color: var(--white);
  background: var(--blue);
}

.Blue:hover {
  box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
}

.White {
  padding: 0.4vw 0.885vw;
  border: 1px solid var(--blue);
  border-radius: 8px;
  font-family: var(--font-family-notosans);
  font-style: normal;
  font-weight: normal;
  color: var(--blue);
  text-decoration: none;
}

.Button:disabled:hover,
.Button:disabled {
  cursor: not-allowed;
  color: var(--white);
  background: #a8b5beff;
  box-shadow: none;
}

.White:hover {
  box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
}

@media only screen and (max-width: 1366px) {
  .Button {
    padding: 0.7vw 0.885vw;
    font-size: 0.9vw;
    line-height: 1.3vw;
  }
}

@media only screen and (max-width: 1024px) {
  .Button {
    padding: 1.1vw 1.2vw;
    font-size: 1.4vw;
    line-height: 1.5vw;
  }
}

@media only screen and (max-width: 768px) {
  .Button {
    padding: 1.2vw 1.8vw;
    font-size: 2.7vw;
    line-height: 2.8vw;
  }
}

@media only screen and (max-width: 430px) {
  .Button {
    height: fit-content;
    padding: 2vw 2.3vw;
    font-size: 3.5vw;
    line-height: 3.7vw;
  }
}

@media only screen and (max-width: 320px) {
  .Button {
    padding: 2.5vw 2.3vw;
    font-size: 3.5vw;
    line-height: 3.6vw;
  }
}
