.SingInForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.FormContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.FormFieldset {
  border: none;
}

.FormInput {
  width: 21.75vw !important;
  height: 2.515vw !important;
  margin: 2px 0 5px 0;
  border: 1px solid #ccdbec;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
}

.ButtonWrapper {
  align-self: center;
  margin-top: 15px;
  width: 18.75vw;
  height: 2.815vw;
}

.LinkWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5vw;
  padding: 0;
}

.LinkWrapper a {
  text-decoration: underline;
  padding: 0;
}

.Title {
  text-align: center;
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1vw;
  line-height: 1.5vw;
}

.ForgetPass {
  display: block;
  position: relative;
  top: -10px;
  text-decoration: none;
  font-size: 0.8vw;
}

.SignUpLink {
  display: block;
  position: relative;
  top: -10px;
  padding-left: 1vw;
  text-decoration: none;
  font-size: 0.8vw;
  color: #0000ee;
  cursor: pointer;
}

.LinkWrapper a:visited {
  color: #551a8b;
}

.Link:hover {
  box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
}

@media only screen and (max-width: 1024px) {
  .Title {
    font-size: 2.5vw;
    margin-bottom: 6vw;
  }

  .FormInput {
    width: 33.75vw !important;
    height: 4.815vw !important;
  }

  .InputLabel {
    font-size: 1.8vw;
    line-height: 2.1vw;
  }

  .ForgetPass {
    padding-left: 1.7vw;
    font-size: 1.7vw;
  }

  .SignUpLink {
    font-size: 1.7vw;
  }

  .ButtonWrapper {
    width: 33.75vw;
    height: 4.815vw;
  }
}

@media only screen and (max-width: 768px) {
  .ForgetPass,
  .SignUpLink {
    font-size: 2vw;
  }

  .SignUpLink {
    padding-left: 2vw;
  }
}

@media only screen and (max-width: 430px) {
  .Title {
    font-size: 4.5vw;
  }

  .FormInput {
    width: 80vw !important;
    height: 10vw !important;
  }

  .InputLabel {
    font-size: 4vw;
    line-height: 4.5vw;
  }

  .ForgetPass {
    font-size: 4vw;
  }

  .SignUpLink {
    font-size: 4vw;
    padding-left: 4vw;
  }

  .ButtonWrapper {
    width: 80vw;
    height: 10vw;
  }
}
