.RecoveryForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.FormContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.FormFieldset {
  border: none;
}

.ButtonWrapper {
  align-self: center;
  width: 18.75vw;
  height: 2.815vw;
  margin-bottom: 10px;
  margin-top: 15px;
}

.Title {
  text-align: center;
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1vw;
  line-height: 1.5vw;
}

.Link {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-top: 1vw;
  cursor: pointer;
  font-family: var(--font-family-roboto);
  font-size: 0.9vw;
  line-height: 1.3vw;
  width: inherit;
  height: inherit;
  text-decoration: none;
  padding: 0.556vh 0.885vw;
  color: var(--white);
  background: var(--blue);
}

.Link:hover {
  box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
}

@media only screen and (max-width: 1024px) {
  .Title {
    font-size: 2.5vw;
  }

  .ButtonWrapper {
    width: 33.75vw;
    height: 4.815vw;
  }

  .Link {
    margin-top: 2.6vw;
    font-size: 1.5vw;
    padding: 1vw 2vw;
  }
}

@media only screen and (max-width: 768px) {
  .Title {
    font-size: 2.5vw;
  }

  .ButtonWrapper {
    width: 40vw;
    height: 5vw;
  }

  .Link {
    font-size: 2.3vw;
    padding: 1.7vw 2.5vw;
  }
}

@media only screen and (max-width: 430px) {
  .Title {
    font-size: 4.5vw;
    margin-bottom: 10vw;
  }

  .ButtonWrapper {
    width: 80vw;
    height: 10vw;
  }

  .Link {
    height: auto;
    font-size: 3.3vw;
    padding: 3.5vw 3vw;
  }
}
