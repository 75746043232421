.ContentWrapper {
  width: 100%;
  height: 100vh;
  padding: 2vw 4vw;
  overflow-y: auto;
}

.Container {
  display: flex;
}

@media screen and (max-width: 1024px) {
  .ContentWrapper {
    padding: 8vw 4vw 2vw 4vw;
  }
}

@media screen and (max-width: 768px) {
  .ContentWrapper {
    padding: 10vw 4vw 3vw 4vw;
  }
}

@media screen and (max-width: 430px) {
  .ContentWrapper {
    padding: 22vw 4vw 4vw 4vw;
  }
}
