.SpecWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.SpecContainer {
  width: calc(33.333% - 10px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin: 0 0 1vw 0;
}

.SpecName {
  margin: 0;
  padding: 0;
  font-family: var(--font-family-notosans);
  font-style: normal;
  font-size: 1vw;
  line-height: 1.4vw;
  color: #40414f;
  font-weight: 700 !important;
}

.DemoFlag {
  padding: 3px 8px;
  color: var(--gray);
  background-color: #cccccc;
  border-radius: 6px;

  font-family: var(--font-family-notosans);
  font-style: normal;
  font-weight: 500;
  font-size: 0.7vw;
  line-height: 1vw;
}

.RateSelectWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1vw;
}

.RateSelect {
  width: 12vw;
  margin-right: 1vw;
}

.ChooseRate {
  font-family: var(--font-family-notosans);
  font-style: normal;
  font-size: 0.8vw;
  line-height: 1.1vw;
  margin-bottom: 0.4vw;
  color: #40414f;
}

.BuyBtn {
  color: #fff;
  background-color: #1677ff;
  border: none;
}

.BuyBtn:hover {
  color: #fff !important;
}

.BuyBtn:focus {
  color: #fff !important;
}

.ButtonWrapper {
  margin-left: 0.5vw;
}

.NoData {
  margin-top: 3vw;
}

@media only screen and (max-width: 1440px) {
  .RateSelect {
    width: 15vw;
    margin-right: 1vw;
  }
}

@media only screen and (max-width: 1280px) {
  .RateSelect {
    width: 16vw;
    height: auto;
    margin-right: 1vw;
  }
}

@media only screen and (max-width: 1024px) {
  .SpecContainer {
    width: 44vw;
  }

  .SpecName {
    font-size: 2vw;
    line-height: 2.4vw;
  }

  .ChooseRate {
    font-size: 1.5vw;
    line-height: 1.7vw;
  }

  .DemoFlag {
    font-size: 1.3vw;
    line-height: 1.4vw;
  }
  .RateSelect {
    width: 20vw;
    margin-right: 1vw;
  }

  .ButtonWrapper {
    margin-top: 1vw;
    margin-left: 1.5vw;
  }
}

@media only screen and (max-width: 768px) {
  .SpecContainer {
    width: 100%;
  }
  .SpecName {
    font-size: 3vw;
    line-height: 3.4vw;
  }
  .DemoFlag {
    font-size: 2vw;
    line-height: 2.2vw;
  }

  .ChooseRate {
    font-size: 2.2vw;
    line-height: 2.5vw;
  }

  .RateSelect {
    width: 30vw;
    margin-right: 2vw;
  }

  .RateSelectWrapper {
    margin-top: 2vw;
  }
}

@media only screen and (max-width: 430px) {
  .SpecContainer {
    width: 100%;
    margin: 0 0 3vw 0;
  }

  .SpecName {
    font-size: 4.5vw;
    line-height: 4.8vw;
  }

  .RateSelect {
    width: 50vw;
    margin-right: 2vw;
  }

  .DemoFlag {
    font-size: 3vw;
    line-height: 3.4vw;
  }

  .ChooseRate {
    font-size: 3.2vw;
    line-height: 3.3vw;
  }

  .RateSelectWrapper {
    margin-top: 4vw;
  }

  .ButtonWrapper {
    margin-top: 3vw;
    margin-left: 3vw;
  }
}
