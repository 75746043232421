.RegistrationForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
}

.FormFieldset {
  border: none;
}

.FormInput {
  width: 21.75vw;

}

.InputWrapper{
  padding-bottom: 8px;
}

.InputLabel {
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8vw;
  line-height: 1.1vw;
  color: #838c9d;
}

.SignInPadding{
  padding-bottom: 1.781vw;
}

.CheckBoxWrapper {
  padding: 0 20px;
  display: flex;
  align-items: center;
  width: 65%;
  color: #14346e;
}

.ButtonWrapper {
  align-self: center;
  margin-top: 15px;
  width: 21.75vw;
  height: 2.815vw;
  margin-bottom: 20px;
}

.DescriptionText {
  width: 20.75vw;
  font-size: 12px;
  color: #838c9d;
  padding-bottom: 40px;
  text-align: center;
}

.Button {
  margin-top: 0.5vw;
  background: none;
  box-shadow: none;
}

.LinkStyles {
  text-decoration: none;
  background: none;
  box-shadow: none;
  margin-top: 1vw;
}

.ConfidentialWrapper {
  width: 30vw;
}

.CheckBox {
  width: 2vw;
}

.LinkWrapper {
  display: flex;
  justify-content: space-between;
}

.Title {
  text-align: center;
  font-family: Noto Sans, sans-serif;
  font-size: 1.1vw;
  line-height: 1.5vw;
}

.ForgetPass {
  display: block;
  position: relative;
  top: -10px;
  right: 15px;
  text-decoration: none;
  font-size: 0.9vw;
}

.SignUpLink {
  display: block;
  position: relative;
  top: -10px;
  padding-left: 1vw;
  text-decoration: none;
  font-size: 0.9vw;
  color: #0000ee;
  cursor: pointer;
}

.ForgetPass:hover,
.SignUpLink:hover {
  text-decoration: underline;
}

.LinkWrapper a:visited {
  color: #551a8b;
}

.Link:hover {
  box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
}

@media only screen and (max-width: 1024px) {

  .Title {
    font-size: 2.5vw;
    margin-bottom: 6vw;
  }

  .DescriptionText{
    width: 38vh;
  }

  .SelectPadding{
    padding-bottom: 70px;
  }

  .CheckBoxWrapper {
    width: 100%;
  }

  .ConfidentialWrapper {
    width: 45vw;
  }

  .FormInput {
    width: 37.75vw;
    height: 4.815vw;
  }

  .CheckBox {
    width: 4vw;
  }

  .ForgetPass {
    padding-left: 1.7vw;
    font-size: 1.7vw;
  }

  .SignUpLink {
    font-size: 1.7vw;
  }

  .ButtonWrapper {
    width: 33.75vw;
    height: 4.815vw;
  }

}

@media only screen and (max-width: 768px) {
  .Title {
    font-size: 3vw;
    margin-bottom: 7vw;
  }

  .CheckBoxWrapper {
    width: 100%;
  }

  .SignUpLink {
    font-size: 2vw;
  }

  .ConfidentialWrapper {
    width: 50vw;
  }

  .CheckBox {
    width: 5vw;
  }

  .SignUpLink {
    padding-left: 2vw;
  }

  .FormInput {
    width: 40vw;
    height: 5vw;
  }

  .SignUpLink {
    font-size: 1.7vw;
  }

  .ButtonWrapper {
    width: 40vw;
    height: 4.815vw;
    margin-top: 40px;
  }

  .SelectPadding{
    padding-bottom: 70px;
  }

  .CheckboxPadding {
    margin-bottom: 4vh;
  }

}

@media only screen and (max-width: 430px) {

  .Title {
    font-size: 4.5vw;
  }

  .CheckBox {
    width: 6vw;
  }

  .ConfidentialWrapper {
    width: 100%;
  }

  .FormInput {
    width: 80vw;
    height: 10vw;
  }

  .InputLabel {
    font-size: 4vw;
    line-height: 4.5vw;
  }

  .ForgetPass {
    font-size: 4vw;
  }

  .SignUpLink {
    font-size: 4vw;
    padding-left: 4vw;
  }

  .ButtonWrapper {
    width: 80vw;
    height: 10vw;
  }
  .CheckBoxWrapper {
    width: 100%;
  }
  .DescriptionText{
    width: 32vh;
  }

  .CheckboxPadding {
    margin-bottom: 2vh;
  }
  .SelectPadding{
    padding-bottom: 70px;
  }

}
