.Title {
  font-family: var(--font-family-notosans);
  font-style: normal;
  font-weight: bold;
  font-size: 1.25vw;
  line-height: 1.7vw;
}

.Wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.Container {
  width: calc(30% - 10px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin: 0 1vw 1vw 0;
}

.WrapperForInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.WrapperForInfo p {
  margin: 0.5vw 0 0.5vw 0;
}

.GroupTitle {
  padding: 0;
  margin: 0;
  font-family: var(--font-family-notosans);
  font-style: normal;
  font-weight: bold;
  font-size: 1vw;
  line-height: 1.4vw;
}

.Count,
.WrapperForInfo {
  font-family: var(--font-family-notosans);
  font-style: normal;
  font-weight: normal;
  font-size: 0.9vw;
  line-height: 1.3vw;
  color: var(--grey-opacity);
}

.ButtonWrapper {
  display: flex;
  justify-content: flex-end;
}

.NoData {
  margin-top: 5vw;
}

.NoTests {
  margin-top: 2vw;
}

@media only screen and (max-width: 1024px) {
  .Container {
    width: 44vw;
  }

  .Title {
    font-size: 2.5vw;
    line-height: 2.7vw;
  }

  .Wrapper {
    margin-bottom: 4vw;
  }

  .GroupTitle {
    font-size: 2vw;
    line-height: 2.2vw;
  }

  .Count,
  .WrapperForInfo {
    padding-top: 2vw;
    font-size: 1.8vw;
    line-height: 1.9vw;
  }

  .ButtonWrapper {
    padding-bottom: 1vw;
  }
}

@media only screen and (max-width: 768px) {
  .Title {
    font-size: 3.5vw;
    line-height: 3.7vw;
  }

  .Wrapper {
    margin-bottom: 6vw;
  }

  .Container {
    width: 100%;
  }

  .GroupTitle {
    font-size: 2.6vw;
    line-height: 2.8vw;
  }

  .Count,
  .WrapperForInfo {
    font-size: 2.2vw;
    line-height: 2.2vw;
  }
}

@media only screen and (max-width: 430px) {
  .Title {
    font-size: 6vw;
    line-height: 6.1vw;
  }

  .Container {
    margin-bottom: 4vw;
  }

  .Container .ant-card-body {
    padding: 10px !important;
  }

  .Wrapper {
    margin-bottom: 10vw;
  }

  .GroupTitle {
    font-size: 4.7vw;
    line-height: 4.8vw;
  }

  .Count,
  .WrapperForInfo {
    font-size: 3.7vw;
    line-height: 4vw;
  }

  .ButtonWrapper {
    padding-bottom: 0;
  }
}
