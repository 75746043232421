.Title {
  margin: 0;
  font-family: var(--font-family-notosans);
  font-style: normal;
  font-weight: normal;
  font-size: 2.6vw;
  line-height: 3.5vw;
  color: var(--blue);
}

.Text {
  padding: 2vh 20vw 5vh 0;
  text-align: justify;
  font-family: var(--font-family-notosans);
  font-style: normal;
  font-weight: normal;
  font-size: 1vw;
  line-height: 1.8vw;
}

@media only screen and (max-width: 1024px) {
  .Title {
    font-size: 4.6vw;
    line-height: 4.7vw;
  }

  .Text {
    padding: 1vh 0 3vw 0;
    font-size: 2.2vw;
    line-height: 3vw;
  }
}

@media only screen and (max-width: 768px) {
  .Title {
    font-size: 6vw;
    line-height: 6.2vw;
  }

  .Text {
    font-size: 3vw;
    line-height: 4vw;
  }
}

@media only screen and (max-width: 430px) {
  .Title {
    font-size: 8vw;
    line-height: 8.2vw;
  }

  .Text {
    padding: 1vw 0 3vw 0;
    font-size: 4.5vw;
    line-height: 6vw;
  }
}
