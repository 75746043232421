.Container {
  display: flex;
  flex-direction: column;
}
.CompletedWrapper {
  display: flex;
  align-items: center;
}

.CompletedContainer {
  display: flex;
  flex-direction: column;
  padding-left: 22vw;
  padding-top: 18vh;
}

.Close {
  display: flex;
  align-items: center;
  position: absolute;
  top: 5vh;
  right: 13vw;
  width: 10.417vw;
  margin-left: 11.719vw;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

.BigCross {
  position: absolute;
  width: 1.25vw;
  height: 1vw;
}

.Quit {
  padding-left: 2.083vw;
  font-family: var(--font-family-notosans);
  font-style: normal;
  font-weight: normal;
  font-size: 2.222vh;
  line-height: 3.241vh;
}

.Title {
  margin: 0;
  padding: 0;
  font-family: var(--font-family-notosans);
  font-style: normal;
  font-weight: 800;
  font-size: 5.7vw;
  line-height: 7.8vw;
  color: #e8d5a3;
}

.PercentsWrapper {
  padding-left: 3vw;
}

.Percents {
  font-family: var(--font-family-notosans);
  font-style: normal;
  font-weight: 900;
  font-size: 5.2vw;
  line-height: 7.08vw;
  color: #7ad1e5;
}

.RightAnswers {
  padding-top: 4.352vh;
  font-family: var(--font-family-notosans);
  font-style: normal;
  font-weight: bold;
  font-size: 1.5vw;
  line-height: 2.13vw;
}

.QuestionsCount {
  padding-top: 1.204vh;
  font-family: var(--font-family-notosans);
  font-style: normal;
  font-weight: normal;
  font-size: 1vw;
  line-height: 1.4vw;
}

.Link {
  text-decoration: none;
}

@media only screen and (max-width: 1024px) {
  .BigCross {
    width: 2.25vw;
    height: 4vw;
  }

  .Quit {
    padding-left: 3vw;
    font-size: 2.5vw;
    line-height: 3vw;
  }

  .CompletedContainer {
    padding-left: 16vw;
  }

  .Title {
    padding-left: 6vw;
    font-size: 6.5vw;
    line-height: 6.8vw;
  }

  .CompletedImage {
    width: 30vw;
  }

  .Percents {
    font-size: 7vw;
    line-height: 7.1vw;
  }

  .RightAnswers {
    padding-top: 2vw;

    font-size: 2.5vw;
    line-height: 2.6vw;
  }

  .QuestionsCount {
    padding-top: 1.204vw;
    font-size: 1.8vw;
    line-height: 2vw;
  }
}

@media only screen and (max-width: 768px) {
  .BigCross {
    width: 2.7vw;
    height: 4.3vw;
  }

  .Quit {
    padding-left: 4vw;
    font-size: 2.8vw;
    line-height: 3.1vw;
  }

  .Title {
    font-size: 7vw;
    line-height: 7.2vw;
  }

  .Percents {
    font-size: 7.5vw;
    line-height: 7.6vw;
  }

  .RightAnswers {
    padding-top: 2.5vw;
    font-size: 3vw;
    line-height: 3.1vw;
  }

  .QuestionsCount {
    padding-top: 1.5vw;
    font-size: 2.3vw;
    line-height: 2.4vw;
  }
}

@media only screen and (max-width: 430px) {
  .Close {
    right: 25vw;
  }

  .BigCross {
    width: 5vw;
    height: 7vw;
  }

  .Quit {
    padding-left: 7vw;
    font-size: 5.5vw;
    line-height: 6.1vw;
  }

  .CompletedWrapper {
    flex-direction: column;
    padding-right: 17vw;
    padding-top: 7vw;
  }

  .CompletedImage {
    height: fit-content;
  }

  .Title {
    width: 60vw;
    text-align: center;
    font-size: 10vw;
    line-height: 10.2vw;
  }

  .Percents {
    font-size: 12.5vw;
    line-height: 12.6vw;
    padding-left: 18vw;
    padding-top: 5vw;
  }

  .RightAnswers {
    padding-top: 4.5vw;
    font-size: 5vw;
    line-height: 5.1vw;
  }

  .QuestionsCount {
    padding-top: 3.5vw;
    font-size: 4.3vw;
    line-height: 4.4vw;
    padding-left: 15vw;
  }
}
