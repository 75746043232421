* {
  font-family: 'Exo 2', sans-serif;
}

.Container {
  display: flex;
  flex-direction: column;
  height: 99vh;
  width: 99vw;
  background: #fafafa;
}

.WrapperNavigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  margin-right: 40px;
}

.Info {
  width: 1.8vw;
  height: 1.8vw;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  z-index: 3;
}

.ControlModalButton {
  align-self: center;
  width: 17vw;
  height: 50px;
  margin: 15px;
}

.ControlContainer {
  display: flex;
  align-items: center;
  margin: 0.7vw 1vw;
  border-radius: 8px;
  height: 65px;
  min-width: 3vw;
}

.Close {
  cursor: pointer;
  text-decoration: none;
  width: 7vw;
  height: inherit;
  border-radius: 8px;
  border: none;
  background-color: #e1f3ff;
}

.Quit {
  font-family: var(--font-family-notosans);
  font-size: 1.2vw;
  line-height: 1.2vw;
  color: #55b9f6;
}

.ModalContent {
  padding: 1.8vw 2vw 2.3vw;
}

.ModalTitle {
  text-align: center;
  font-family: var(--font-family-notosans);
  font-weight: bold;
  font-size: 1.25vw;
  line-height: 1.8vw;
  color: var(--gray);
}

.ModalDesc {
  padding: 1vw 0 1.5vw 0;
  margin: 0;
  font-family: var(--font-family-notosans);
  font-size: 1vw;
  line-height: 1.8vw;
  color: var(--gray);
}

.ButtonContainer {
  display: flex;
  justify-content: space-evenly;
}

.QuitLink {
  padding: 0.8vw 1.6vw;
  border: 2px solid var(--blue);
  border-radius: 0.8vw;
  color: var(--white);
  background: var(--blue);
  font-family: var(--font-family-roboto);
  font-size: 0.9vw;
  line-height: 1.3vw;
  text-decoration: none;
  cursor: pointer;
}

.QuitLink:hover {
  box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
}

.ModalButton {
  padding: 0.8vw 1.6vw;
  border-radius: 0.8vw;
}

@media only screen and (max-width: 1024px) {
  .WrapperNavigation {
    margin-right: 4vw;
    padding: 2vw 0 1vw 0;
  }

  .Info {
    width: 3vw;
    height: 3vw;
  }

  .Close {
    margin-top: 0.6vw;
    width: 12vw;
  }

  .Quit {
    font-size: 2.5vw;
  }

  .ModalContent {
    padding: 4.8vw 5vw 5.3vw;
  }

  .ModalTitle {
    font-size: 2.25vw;
    line-height: 2.5vw;
  }

  .ModalDesc {
    padding: 2vw 0 3vw 0;
    font-size: 1.5vw;
    line-height: 1.8vw;
  }

  .ModalButton {
    padding: 1vw 1.8vw;
  }

  .ControlContainer {
    height: 50px;
    min-width: 10vw;
  }
}

@media only screen and (max-width: 768px) {
  .WrapperNavigation {
    margin-right: 10vw;
  }

  .Info {
    width: 4vw;
    height: 4vw;
  }

  .Close {
    width: 14vw;
  }

  .Quit {
    font-size: 3vw;
  }

  .ModalContent {
    padding: 5vw 5.2vw 5.6vw;
  }

  .ModalTitle {
    font-size: 3vw;
    line-height: 3.5vw;
  }

  .ModalDesc {
    padding: 2.5vw 0 3.5vw 0;
    font-size: 1.9vw;
    line-height: 2vw;
  }

  .QuitLink {
    padding: 1.8vw 3.5vw;
    font-size: 2vw;
    line-height: 2.2vw;
  }

  .ModalButton {
    padding: 1.8vw 3.5vw;
    font-size: 2vw;
    line-height: 2.2vw;
  }
}

@media only screen and (max-width: 430px) {
  .WrapperNavigation {
    margin: 3vw 5vw 0 0;
  }

  .ControlContainer {
    margin: 0;
    width: 20vw;
  }

  .Info {
    width: 7vw;
    height: 7vw;
  }

  .Close {
    width: 20vw;
  }

  .Quit {
    font-size: 5vw;
  }

  .ModalContent {
    padding: 5vw 4vw 7vw;
  }

  .ModalTitle {
    font-size: 6vw;
    line-height: 6.5vw;
  }

  .ModalDesc {
    padding: 4vw 0 5vw 0;
    font-size: 3.45vw;
    line-height: 3.7vw;
  }

  .QuitLink {
    padding: 3vw 4vw;
    border-radius: 1.8vw;
    font-size: 3.5vw;
    line-height: 3.7vw;
  }

  .ModalButton {
    padding: 3vw 4vw;
    border-radius: 1.8vw;
    font-size: 3.5vw;
    line-height: 3.7vw;
  }

  .ControlModalButton {
    width: 25vw;
    height: 12vw;
    font-size: 4vw;
  }
}
